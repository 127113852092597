import React, { Component } from 'react'
import CustomerDetails from '../CustomerDetails/CustomerDetails'
import './OrdersPage.css'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Spinner from 'react-md-spinner'

export default class OrdersPage extends Component {
    constructor() {
        super()
        this.state = {
            firstName: "",
            lastName: "",
            email: "",
            orderNumber: "",
            date: "",
            customerSelected: null,
            addCustomer: false,
            orders: [],
            searchFetchOutstanding: false,
            ordersMessage: "",
            sortedBy: 'date',
            arrowRotated: false,
            currentSearch: {
                firstName: null,
                lastName: null,
                email: null,
                orderNumber: null,
                date: null
            }
        }
    }

    componentDidMount() {
        this.getTodaysOrders()
    }

    /*
    * @param {string} firstName
    * @param {string} lastName
    * @param {string} email
    * @param {string} orderNumber
    * @param {string} date
    * @return {void} state is modified in function body
    * 
    * fetchOrders accepts search parameters and updates the displayed users table with
    * returned users matching the criteria
    */
    fetchOrders = (firstName, lastName, email, orderNumber, date) => {
        this.setState({
            searchFetchOutstanding: true,
            currentSearch: {
                firstName,
                lastName,
                email,
                orderNumber,
                date
            }
        })
        fetch(`${this.props.route}/adminlookup.webapi`, {
            method: 'POST',
            headers: {
                'Content-Type': 'text/plain',
				'Token': this.props.userData.Token
            },
            body: JSON.stringify({
                firstName,
                lastName,
                email,
                orderNumber,
                date
            })
        })
        .then(response => {
            if (response.status === 401) {
                this.props.setAuthenticationError()
                throw new Error('Failed to authenticate request')
            } else if (response.ok) {
                return response.text()
            } else {
                throw new Error('Failed to fetch orders')
            }
        })
        .then(result => {
            console.log(JSON.parse(result))
            this.setState({searchFetchOutstanding: false, orders: JSON.parse(result), sortedBy: 'date'}, this.sortOrderHistory('orderNum'))
        })
        .catch(error => {
            toast(error)
            console.log(error)
            this.setState({searchFetchOutstanding: false})
        })
    }


    /*
    * @param {string} dateString an unformatted date string
    * @return {string} Ex: "Apr 6th, 2021, 10:05 AM"
    * 
    * formatDate accepts an unformatted date string and returns a formatted string
    */
    formatDate = (dateString) => {
        let date = new Date(dateString)
        const MONTHS = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
        let day = date.getDate()
        let suffix = this.getSuffix(date.getDate())
        let month = MONTHS[date.getMonth()]
        let year = date.getFullYear()
        let hour = date.getHours()
        let timePeriod = 'AM'
        let minute = date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes()

        if (hour === 0) {
            hour = 12
        } else if (hour === 12) {
            timePeriod = 'PM'
        } else if (hour > 12) {
            timePeriod = 'PM'
            hour -= 12
        }

        return `${month} ${day}${suffix}, ${year}, ${hour}:${minute} ${timePeriod}`
    }

    /*
    * @param {number} day
    * @return {string} suffix of the passed day
    * 
    * getSuffix accepts a number and returns the appropriate suffix
    */
    getSuffix = (day) => {
        if (day % 10 === 1 && day !== 11) {
            return 'st'
        } else if (day % 10 === 2 && day !== 12) {
            return 'nd'
        } else if (day % 10 === 3 && day !== 13) {
            return 'rd'
        } else {
            return 'th'
        }
    }


    /*
    * @return {void}
    *
    * getTodaysOrders sets the current displayed orders to those of the current date
    */
    getTodaysOrders = () => {
        let date = new Date()
        let month = (date.getMonth() + 1) < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1
        let day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()
        let year = date.getFullYear()
        let dateString = `${month}-${day}-${year}`
        this.fetchOrders(null, null, null, null, dateString)
        this.setState({ordersMessage: `Displaying orders for ${dateString}`})
    }

    /*
    * @return {void}
    *
    * searchUsers validates search criteria and, if valid, calls the fetchOrders function
    * The function then updates the search message
    */
    searchUsers = () => {
        let {firstName, lastName, email, orderNumber, date} = this.state
        if (firstName !== "" || lastName !== "" || email !== "" || orderNumber !== "" || date !== "") {
            console.log('fetching orders')
            this.fetchOrders(firstName, lastName, email, orderNumber, date)
            let searchArray = []
            if (firstName) {
                searchArray.push(firstName)
            }
            if (lastName) {
                searchArray.push(lastName)
            }
            if (email) {
                searchArray.push(email)
            }
            if (orderNumber) {
                searchArray.push(orderNumber)
            }
            if (date) {
                searchArray.push(date)
            }
            this.setState({ordersMessage: `Displaying orders for "${searchArray.join(', ')}"`})
        } else {
            this.getTodaysOrders()
        }
    }

    /*
    * @return {void}
    *
    * clearSearch clears all search inputs
    */
    clearSearch = () => {
        this.setState({
            firstName: '',
            lastName: '',
            email: '',
            orderNumber: '',
            date: ''
        })
    }

    /*
    * @param {Object} customerOrderInformation
    *
    * selectCustomer fetches order information for a user via the customerId
    */
    selectCustomer = (customerId, resetScroll) => {
        this.setState({customerFetchOutstanding: true})
        fetch(`${this.props.route}/adminselect.webapi`, {
            method: 'POST',
            headers: {
                'Content-Type': 'text/plain',
				'Token': this.props.userData.Token
            },
            body: JSON.stringify({
                CustomerId: customerId
            })
        })
        .then(response => {
            if (response.status === 401) {
                this.props.setAuthenticationError()
                throw new Error('Failed to authenticate request')
            } else if (response.ok) {
                return response.text()
            } else {
                throw new Error('Failed to get customer info')
            }
        })
        .then(result => {
            console.log(JSON.parse(result))
            if (resetScroll) {
                window.scrollTo(0, 0);
            }
            this.setState({customerSelected: JSON.parse(result), customerFetchOutstanding: false})
        })
        .catch(error => {
            toast(error)
            console.log(error)
            this.setState({customerFetchOutstanding: false})
        })
    }

    /*
    * @param {string}
    * @return {void}
    *
    * sortOrderHistory takes a string and sorts the orders array depending on the strings value
    */
    sortOrderHistory = (sortByValue) => {
        let orders = [...this.state.orders]
        if (this.state.sortedBy === sortByValue) {
            orders.reverse()
            this.setState({arrowRotated: !this.state.arrowRotated})
        } else {
            switch(sortByValue) {
                case 'orderNum':
                    orders.sort((a, b) => a.OrderNumber - b.OrderNumber)
                    break;
                case 'name':
                    orders.sort((a, b) => {
                        if(a.CustomerFirstName < b.CustomerFirstName) { return -1; }
                        if(a.CustomerFirstName > b.CustomerFirstName) { return 1; }
                        return 0;
                    })
                    break;
                case 'email':
                    orders.sort((a, b) => {
                        if(a.CustomerEmail < b.CustomerEmail) { return -1; }
                        if(a.CustomerEmail > b.CustomerEmail) { return 1; }
                        return 0;
                    })
                    break;
                case 'demo':
                    orders.sort((a, b) => {
                        if(!b.Demo && a.Demo) { return -1; }
                        if(!a.Demo && b.Demo) { return 1; }
                        return 0;
                    })
                    break;
                case 'purchaseDate':
                    orders.sort((a, b) => new Date(a.DateCustCreated) - new Date(b.DateCustCreated))
                    break;
                case 'modifiedDate':
                    orders.sort((a, b) => new Date(a.DateCustModified) - new Date(b.DateCustModified))
                    break;
                default:
                    break;
            }
            this.setState({arrowRotated: false})
        }
        this.setState({orders, sortedBy: sortByValue})
    }

    refreshOrders = () => {
        let {firstName, lastName, email, orderNumber, date} = this.state.currentSearch
        this.fetchOrders(firstName, lastName, email, orderNumber, date)
    }

    render() {
        return (
            <div className='admin-panel-orders-container'>
                {!this.state.customerSelected && !this.state.addCustomer ? 
                    <div>
                        <div className='admin-panel-orders-header-wrapper'>
                            <h1 className='admin-panel-page-title-text'>Orders</h1>
                            <div className='admin-panel-orders-button-wrapper'>
                                {this.state.searchFetchOutstanding ?
                                    <div className='admin-panel-customer-details-spinner-container' style={{transform: 'none', left: 0, width: 105, display: 'grid', justifyItems: 'center', alignItems: 'center'}}>
                                        <Spinner size={35} singleColor={'#1c4670'}/>
                                    </div>
                                :
                                    <button className='admin-panel-orders-add-customer-button' onClick={this.refreshOrders}>Refresh</button>
                                }
                                <button className='admin-panel-orders-add-customer-button' onClick={() => this.setState({addCustomer: true})}>Add Customer</button>
                            </div>
                        </div>
                        <div className='admin-panel-orders-search-container'>
                            <div className='admin-panel-orders-search-field-container'>
                                <label className='admin-panel-orders-search-field-title' htmlFor='first-name'>First Name</label>
                                <input className='admin-panel-orders-search-field-input' id='first-name' value={this.state.firstName} onChange={(event) => this.setState({firstName: event.target.value})} onKeyDown={(value) => value.key === 'Enter' && this.searchUsers()}/>
                            </div>
                            <div className='admin-panel-orders-search-field-container'>
                                <label className='admin-panel-orders-search-field-title' htmlFor='last-name'>Last Name</label>
                                <input className='admin-panel-orders-search-field-input' id='last-name' value={this.state.lastName} onChange={(event) => this.setState({lastName: event.target.value})} onKeyDown={(value) => value.key === 'Enter' && this.searchUsers()}/>
                            </div>
                            <div className='admin-panel-orders-search-field-container'>
                                <label className='admin-panel-orders-search-field-title' htmlFor='email'>Email</label>
                                <input className='admin-panel-orders-search-field-input' type='email' id='email' value={this.state.email} onChange={(event) => this.setState({email: event.target.value})} onKeyDown={(value) => value.key === 'Enter' && this.searchUsers()}/>
                            </div>
                            <div className='admin-panel-orders-search-field-container'>
                                <label className='admin-panel-orders-search-field-title' htmlFor='order-number'>Order Number</label>
                                <input className='admin-panel-orders-search-field-input' id='order-number' value={this.state.orderNumber} onChange={(event) => this.setState({orderNumber: event.target.value})} onKeyDown={(value) => value.key === 'Enter' && this.searchUsers()}/>
                            </div>
                            <div className='admin-panel-orders-search-field-container'>
                                <label className='admin-panel-orders-search-field-title' htmlFor='date'>Date</label>
                                <input className='admin-panel-orders-search-field-input' id='date' value={this.state.date} onChange={(event) => this.setState({date: event.target.value})} onKeyDown={(value) => value.key === 'Enter' && this.searchUsers()}/>
                            </div>
                            <div className='admin-panel-orders-search-button-container'>
                                <button className='admin-panel-orders-clear-button' onClick={this.clearSearch}>Clear</button>
                                <div className='admin-panel-orders-search-button-wrapper'>
                                    {this.state.searchFetchOutstanding ?
                                        <Spinner size={35} singleColor={'#1c4670'}/>
                                    :
                                        <button className='admin-panel-orders-search-button' onClick={this.searchUsers}>Search</button>
                                    }
                                </div>
                            </div>
                        </div>
                        <p className='admin-panel-orders-info-text'>{this.state.ordersMessage}</p>
                        <div className='admin-panel-orders-history-container'>
                            <div className='admin-panel-orders-history-table-container'>
                                <div className='admin-panel-orders-history-table-wrapper'>
                                    <div className='admin-panel-orders-history-titles-container'>
                                        <div className='admin-panel-orders-history-title-wrapper' style={{justifySelf: 'start', paddingLeft: '20px'}}>
                                            <p className='admin-panel-orders-history-title' onClick={() => this.sortOrderHistory('orderNum')}>Order #</p>
                                            <div className={`admin-panel-orders-history-arrow ${this.state.sortedBy === 'orderNum' && this.state.arrowRotated ? 'admin-panel-orders-history-arrow-rotated' : this.state.sortedBy !== 'orderNum' ? 'admin-panel-orders-history-arrow-hidden' : ''}`} />
                                        </div>
                                        <div className='admin-panel-orders-history-title-wrapper'>
                                            <p className='admin-panel-orders-history-title' onClick={() => this.sortOrderHistory('name')}>Name</p>
                                            <div className={`admin-panel-orders-history-arrow ${this.state.sortedBy === 'name' && this.state.arrowRotated ? 'admin-panel-orders-history-arrow-rotated' : this.state.sortedBy !== 'name' ? 'admin-panel-orders-history-arrow-hidden' : ''}`} />
                                        </div>
                                        <div className='admin-panel-orders-history-title-wrapper'>
                                            <p className='admin-panel-orders-history-title' onClick={() => this.sortOrderHistory('email')}>Email</p>
                                            <div className={`admin-panel-orders-history-arrow ${this.state.sortedBy === 'email' && this.state.arrowRotated ? 'admin-panel-orders-history-arrow-rotated' : this.state.sortedBy !== 'email' ? 'admin-panel-orders-history-arrow-hidden' : ''}`} />
                                        </div>
                                        <div className='admin-panel-orders-history-title-wrapper'>
                                            <p className='admin-panel-orders-history-title' onClick={() => this.sortOrderHistory('demo')}>Demo</p>
                                            <div className={`admin-panel-orders-history-arrow ${this.state.sortedBy === 'demo' && this.state.arrowRotated ? 'admin-panel-orders-history-arrow-rotated' : this.state.sortedBy !== 'demo' ? 'admin-panel-orders-history-arrow-hidden' : ''}`} />
                                        </div>
                                        <div className='admin-panel-orders-history-title-wrapper'>
                                            <p className='admin-panel-orders-history-title' onClick={() => this.sortOrderHistory('purchaseDate')}>Purchase Date</p>
                                            <div className={`admin-panel-orders-history-arrow ${this.state.sortedBy === 'purchaseDate' && this.state.arrowRotated ? 'admin-panel-orders-history-arrow-rotated' : this.state.sortedBy !== 'purchaseDate' ? 'admin-panel-orders-history-arrow-hidden' : ''}`} />
                                        </div>
                                        <div className='admin-panel-orders-history-title-wrapper'>
                                            <p className='admin-panel-orders-history-title' onClick={() => this.sortOrderHistory('modifiedDate')}>Modified Date</p>
                                            <div className={`admin-panel-orders-history-arrow ${this.state.sortedBy === 'modifiedDate' && this.state.arrowRotated ? 'admin-panel-orders-history-arrow-rotated' : this.state.sortedBy !== 'modifiedDate' ? 'admin-panel-orders-history-arrow-hidden' : ''}`} />
                                        </div>
                                    </div>
                                    {this.state.orders.map((order, index) => (
                                        <div className='admin-panel-orders-history-data-container' onClick={() => this.selectCustomer(order.CustomerId, true)} key={index}>
                                            <p className='admin-panel-orders-history-data' style={{justifySelf: 'start', paddingLeft: '20px'}}>#{order.OrderNumber ? order.OrderNumber : order.CustomerId}</p>
                                            <p className='admin-panel-orders-history-data'>{order.CustomerFirstName} {order.CustomerLastName}</p>
                                            <p className='admin-panel-orders-history-data'>{order.CustomerEmail}</p>
                                            <p className='admin-panel-orders-history-data'>{order.Demo ? 'True' : 'False'}</p>
                                            <p className='admin-panel-orders-history-data'>{this.formatDate(order.DateCustCreated)}</p>
                                            <p className='admin-panel-orders-history-data'>{this.formatDate(order.DateCustModified)}</p>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className='admin-panel-order-history-footer-container'>
                                <p className='admin-panel-order-history-footer-text'>Displaying {this.state.orders.length} Order{this.state.orders.length !== 1 ? "s" : ""}</p>
                            </div>
                            {this.state.customerFetchOutstanding &&
                                <div className='admin-panel-order-history-spinner-container'>
                                    <Spinner size={35} singleColor={'#1c4670'}/>
                                </div>
                            }
                        </div>
                    </div>
                : this.state.addCustomer ?
                    <CustomerDetails 
                        closeDetails={() => this.setState({customerSelected: null, addCustomer: false})}
                        addCustomer={true}
                        userData={this.props.userData}
                        route={this.props.route}
                        setAuthenticationError={this.props.setAuthenticationError}
                    />
                :
                    <CustomerDetails
                        closeDetails={() => this.setState({customerSelected: null})}
                        addCustomer={false}
                        userData={this.props.userData}
                        customerSelected={this.state.customerSelected}
                        route={this.props.route}
                        selectCustomer={this.selectCustomer}
                        setAuthenticationError={this.props.setAuthenticationError}
                    />
                }
                <ToastContainer />
            </div>
        )
    }
}
