import './App.css';
import AdminPanel from './AdminPanel/AdminPanel'
import './Simulation.css'

function App() {
  return (
    <AdminPanel />
  );
}

export default App;
