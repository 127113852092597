import React, { Component } from 'react'
import logo from '../images/cdmCasesUncropped.png';
import logoCropped from '../images/cdmCases.png'
import './AdminPanel.css'
import OrdersPage from './Components/OrdersPage/OrdersPage';
import OverviewPage from './Components/OverviewPage/OverviewPage';
import { UnauthenticatedHandler } from './Components/UnauthenticatedPopup/UnauthenticatedHandler';

export default class AdminPanel extends Component {
    constructor() {
        super()
        this.state = {
            email: "",
            password: "",
			tfa: "",
			userData: null,
			captcha: false,
			route: "https://app.ccdmcases.com",
			selectedPage: "Overview",
			stats: null,
			navDrawerOpen: false,
			authenticationError: false,
        }
    }

    fetchLoginToken = () => {
        let email = this.state.email
		let password = this.state.password
		let tfa = this.state.tfa === "" ? "000000" : this.state.tfa
		if (!this.state.captcha || (this.state.captcha && this.state.captchaComplete)) {
			fetch(`${this.state.route}/loginadmin.webapi`, {
				method: 'POST',
				headers: {
					'Content-Type': 'text/plain',
				},
				body: JSON.stringify({
					userName: email,
					password: password,
					twofactor: tfa
				})
			})
			.then(response => response.text())
			.then(result => {
				if (result.includes('Token')) {
					let userData = JSON.parse(result)
					this.setState({
						userData,
						tfa: ""
					});
					window.scrollTo(0, 0);
				}
				else {
					result = result.replace('{', '');
					result = result.replace('}', '');
					this.setState({
						loginError: result,
						loginErrorCount: this.state.loginErrorCount + 1,
					});
					if (this.state.loginErrorCount === 5) {
						this.setState({captcha: true});
					}
				}
				this.setState({password: ''});
			})
			.catch(error => {
				console.log('error', error);
			});
		}
		else {
			this.setState({loginError: 'You must complete the captcha'});
		}
	}

	changePage = (newPage) => {
		this.setState({selectedPage: newPage, navDrawerOpen: false})
	}

    render() {
        return (
            <div className='app'>
				{/* {!this.state.userData ? */}
				{!this.state.userData ?
					<div>
						<div className='admin-login-logo'>
							<img className='admin-logo' src={logo} alt='Canada CDMCases'/>
							<div className='login-color' />
						</div>
						<div className={`login-screen`}>
							<div className='login-box'>
								<h1 className='admin-logo-text'>Admin</h1>
								<div className='login-box-element'>
									<div>Email:</div>
									<input className='login-field' type='email' value={this.state.email} onChange={(e) => this.setState({email: e.target.value})}/>
								</div>
								<div className='login-box-element'>
									<div>Password:</div>
									<input className='login-field' type='password' value={this.state.password} onChange={(e) => this.setState({password: e.target.value})}/>
								</div>
								<div className='login-box-element'>
									<div>TFA:</div>
									<input className='login-field' type='text' value={this.state.tfa} onChange={(e) => this.setState({tfa: e.target.value})} onKeyDown={(value) => value.key === 'Enter' && this.fetchLoginToken()}/>
								</div>
								<div className='login-message'>{this.state.loginError}</div>
								<div className='login-box-element'>
									<input className='login-button' type='button' value='Sign in' onClick={this.fetchLoginToken}/>
								</div>
							</div>
						</div>
					</div>
				:
					<div className='admin-panel-wrapper'>
						{/* <div className='admin-panel-sidebar-container'>
							<img className='admin-panel-sidebar-logo' src={logoCropped}/>
							<div className='admin-panel-sidebar-links-wrapper'>
								<div className={`admin-panel-sidebar-link ${this.state.selectedPage === "Overview" ? "admin-panel-sidebar-link-selected" : ""}`} onClick={() => this.changePage("Overview")}>
									<p className='admin-panel-sidebar-link-text'>Overview</p>
								</div>
								<div className={`admin-panel-sidebar-link ${this.state.selectedPage === "Orders" ? "admin-panel-sidebar-link-selected" : ""}`} onClick={() => this.changePage("Orders")}>
									<p className='admin-panel-sidebar-link-text'>Orders</p>
								</div>
							</div>
							<div className='admin-panel-sidebar-footer-container'>
								<button className='admin-panel-sidebar-logout-button' onClick={() => this.setState({userData: null})}>Sign Out</button>
							</div>
						</div> */}
						<div className='admin-panel-navbar-container'>
							<img className='admin-panel-navbar-logo' src={logoCropped} />
							<div className='admin-panel-navbar-links-wrapper'>
								<p className={`admin-panel-navbar-link ${this.state.selectedPage === 'Overview' ? 'admin-panel-navbar-link-selected' : ''}`} onClick={() => this.changePage('Overview')}>Overview</p>
								<p className={`admin-panel-navbar-link ${this.state.selectedPage === 'Orders' ? 'admin-panel-navbar-link-selected' : ''}`} onClick={() => this.changePage('Orders')}>Orders</p>
								<button className='admin-panel-navbar-logout-button' onClick={() => this.setState({userData: null})}>Sign Out</button>
							</div>
							<div className='admin-panel-navbar-burger-container' onClick={() => this.setState({navDrawerOpen: true})}>
								<div className='admin-panel-navbar-burger-dash' />
								<div className='admin-panel-navbar-burger-dash' />
								<div className='admin-panel-navbar-burger-dash' />
							</div>
						</div>
						<div className={`admin-panel-mobile-nav-container ${this.state.navDrawerOpen ? 'admin-panel-mobile-nav-container-open' : ''}`}>
							<button className='admin-panel-mobile-nav-close-button' onClick={() => this.setState({navDrawerOpen: false})}/>
							<img className='admin-panel-mobile-nav-logo' src={logoCropped} />
							<div className='admin-panel-mobile-nav-links-wrapper'>
								<p className={`admin-panel-mobile-nav-link ${this.state.selectedPage === 'Overview' ? 'admin-panel-navbar-link-selected' : ''}`} onClick={() => this.changePage('Overview')}>Overview</p>
								<p className={`admin-panel-mobile-nav-link ${this.state.selectedPage === 'Orders' ? 'admin-panel-navbar-link-selected' : ''}`} onClick={() => this.changePage('Orders')}>Orders</p>
								<button className='admin-panel-mobile-nav-logout-button' onClick={() => this.setState({userData: null, navDrawerOpen: false})}>Sign Out</button>
							</div>
						</div>
						{this.state.selectedPage === "Overview" ?
							<OverviewPage
								route={this.state.route}
								userData={this.state.userData}
								setAuthenticationError={() => this.setState({authenticationError: true})}
							/>
						:
							<OrdersPage
								route={this.state.route}
								userData={this.state.userData}
								setAuthenticationError={() => this.setState({authenticationError: true})}
							/>
						}
						{this.state.authenticationError && <UnauthenticatedHandler />}
					</div>
				}
			</div>
        )
    }
}
