import { findAllByDisplayValue } from '@testing-library/react'
import React, { Component } from 'react'
import './CustomerDetails.css'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Switch from './Switch';
import Spinner from 'react-md-spinner'

export default class CustomerDetails extends Component {
    constructor() {
        super()
        this.state = {
            customerId: "",
            firstName: "",
            lastName: "",
            email: "",
            username: "",
            password: "",
            address: "",
            address2: "",
            zip: "",
            state: "",
            city: "",
            phoneNumber: "",
            lastLoggedIn: "",
            notes: "",
            licenseExpiration: "",
            dateCreated: "",
            dateModified: "",
            defaultIp: "",
            limitIp: false,
            limitCases: false,
            demo: false,
            purchaseIp: "",
            purchasePrice: "",
            taxes: "",
            productId: "",
            orderNumber: "",
            whereDidYouHear: "",
            paymentMethod: "",
            conversionFromDemo: false,
            timeZone: "",
            loadingPopup: false,
            invoiceFetchOutstanding: false,
            updateFetchOutstanding: false,
        }
    }

    componentDidMount() {
        if (!this.props.addCustomer) {
            let { customerSelected } = this.props
            this.setState({
                customerId: customerSelected.CustomerId,
                firstName: customerSelected.CustomerFirstName,
                lastName: customerSelected.CustomerLastName,
                email: customerSelected.CustomerEmail,
                username: customerSelected.CustomerUserName,
                password: customerSelected.CustomerPassword,
                address: customerSelected.CustomerAddress,
                address2: customerSelected.CustomerAddress2,
                zip: customerSelected.CustomerZip,
                state: customerSelected.CustomerState,
                city: customerSelected.CustomerCity,
                phoneNumber: customerSelected.CustomerPhone,
                lastLoggedIn: customerSelected.LastLoggedIn,
                notes: customerSelected.Notes,
                licenseExpiration: customerSelected.LicenseExpiration,
                dateCreated: customerSelected.DateCustCreated,
                dateModified: customerSelected.DateCustModified,
                defaultIp: customerSelected.DefaultIpAddress,
                limitIp: customerSelected.LimitToOneIp,
                limitCases: customerSelected.LimitFiveCases,
                demo: customerSelected.Demo,
                purchaseIp: customerSelected.CustomerPurchaseIp,
                purchasePrice: customerSelected.CustomerPurchasePrice,
                taxes: customerSelected.CustomerTaxes,
                productId: customerSelected.CustomerProductId,
                orderNumber: customerSelected.OrderNumber,
                whereDidYouHear: customerSelected.WhereDidYouHear,
                paymentMethod: customerSelected.PaymentMethod,
                conversionFromDemo: customerSelected.ConversionFromDemo,
                timeZone: customerSelected.TimeZone,
                loadingPopup: customerSelected.LoadingPopUp,
            })
        }
    }

    /*
    * @return {void}
    *
    * saveHandler determines whether to update an existing profile or create a new profile and calls
    * the appropriate fetch handler
    */
    saveHandler = () => {
        if(!this.props.addCustomer) {
            this.fetchSaveChanges()
        } else {
            this.fetchAddCustomer()
        }
    }

    /*
    * @return {void}
    *
    * fetchSaveChanges updates an existing user profile with updated input data
    */
    fetchSaveChanges = () => {
        this.setState({updateFetchOutstanding: true})
        fetch(`${this.props.route}/adminupdate.webapi`, {
            method: 'POST',
            headers: {
                'Content-Type': 'text/plain',
				'Token': this.props.userData.Token
            },
            body: JSON.stringify({
                "CustomerId": this.state.customerId,
                "CustomerFirstName": this.state.firstName,
                "CustomerLastName": this.state.lastName,
                "CustomerEmail": this.state.email,
                "CustomerUserName": this.state.username,
                "CustomerPassword": this.state.password,
                "LicenseExpiration": this.state.licenseExpiration,
                "DateCustCreated": this.state.dateCreated,
                "DateCustModified": this.state.dateModified,
                "Demo": this.state.demo,
                "WhereDidYouHear": this.state.whereDidYouHear,
                "LimitedToOneIp": this.state.limitIp,
                "DefaultIpAddress": this.state.defaultIp,
                "LimitFiveCases": this.state.limitCases,
                "CustomerAddress": this.state.address,
                "CustomerAddress2": this.state.address2,
                "CustomerZip": this.state.zip,
                "CustomerState": this.state.state,
                "CustomerCity": this.state.city,
                "CustomerPurchaseIp": this.state.purchaseIp,
                "CustomerPurchasePrice": this.state.purchasePrice,
                "CustomerPhone": this.state.phoneNumber,
                "CustomerProductId": this.state.productId,
                "CustomerTaxes": this.state.taxes,
                "Notes": this.state.notes,
                "ConversionFromDemo": this.state.conversionFromDemo,
                "OrderNumber": this.state.orderNumber,
                "LastLoggedIn": this.state.lastLoggedIn,
                "TimeZone": this.state.timeZone,
                "LoadingPopUp": this.state.loadingPopup,
                "PaymentMethod": this.state.paymentMethod
            })
        })
        .then(response => {
            if (response.status === 401) {
                this.props.setAuthenticationError()
                throw new Error('Failed to authenticate request')
            } else if (response.ok) {
                return response.text()
            } else {
                throw new Error('Failed to update customer info')
            }
        })
        .then(result => {
            console.log(result)
            toast(result)
            this.setState({updateFetchOutstanding: false}, () => {
                this.props.selectCustomer(this.state.customerId, false)
            })
        })
        .catch(error => {
            toast(error)
            console.log(error)
            this.setState({updateFetchOutstanding: false})
        })
    }

    /*
    * @return {void}
    *
    * fetchResendInvoice sends an email to the user containing the invoice from their purchase
    */
    fetchResendInvoice = () => {
        this.setState({invoiceFetchOutstanding: true})
        fetch(`${this.props.route}/adminemailinvoice.webapi`, {
            method: 'POST',
            headers: {
                'Content-Type': 'text/plain',
				'Token': this.props.userData.Token
            },
            body: JSON.stringify({
                "CustomerId": this.state.customerId,
            })
        })
        .then(response => {
            if (response.status === 401) {
                this.props.setAuthenticationError()
                throw new Error('Failed to authenticate request')
            } else if (response.ok) {
                return response.text()
            } else {
                throw new Error('Failed to resend invoice')
            }
        })
        .then(result => {
            console.log(result)
            toast(result)
            this.setState({invoiceFetchOutstanding: false})
        })
        .catch(error => {
            toast(error)
            console.log(error)
            this.setState({invoiceFetchOutstanding: false})
        })
    }

    /*
    * @return {void}
    *
    * fetchAddCustomer adds a new customer to the database from input data
    */
    fetchAddCustomer = () => {
        this.setState({updateFetchOutstanding: true})
        fetch(`${this.props.route}/adminaddnewcustomer.webapi`, {
            method: 'POST',
            headers: {
                'Content-Type': 'text/plain',
				'Token': this.props.userData.Token
            },
            body: JSON.stringify({
                "CustomerId": this.state.customerId,
                "CustomerFirstName": this.state.firstName,
                "CustomerLastName": this.state.lastName,
                "CustomerEmail": this.state.email,
                "CustomerUserName": this.state.username,
                "CustomerPassword": this.state.password,
                "LicenseExpiration": this.state.licenseExpiration,
                "DateCustCreated": this.state.dateCreated,
                "DateCustModified": this.state.dateModified,
                "Demo": this.state.demo,
                "WhereDidYouHear": this.state.whereDidYouHear,
                "LimitedToOneIp": this.state.limitIp,
                "DefaultIpAddress": this.state.defaultIp,
                "LimitFiveCases": this.state.limitCases,
                "CustomerAddress": this.state.address,
                "CustomerAddress2": this.state.address2,
                "CustomerZip": this.state.zip,
                "CustomerState": this.state.state,
                "CustomerCity": this.state.city,
                "CustomerPurchaseIp": this.state.purchaseIp,
                "CustomerPurchasePrice": this.state.purchasePrice,
                "CustomerPhone": this.state.phoneNumber,
                "CustomerProductId": this.state.productId,
                "CustomerTaxes": this.state.taxes,
                "Notes": this.state.notes,
                "ConversionFromDemo": this.state.conversionFromDemo,
                "OrderNumber": this.state.orderNumber,
                "LastLoggedIn": this.state.lastLoggedIn,
                "TimeZone": this.state.timeZone,
                "LoadingPopUp": this.state.loadingPopup,
                "PaymentMethod": this.state.paymentMethod
            })
        })
        .then(response => {
            if (response.status === 401) {
                this.props.setAuthenticationError()
                throw new Error('Failed to authenticate request')
            } else if (response.ok) {
                return response.text()
            } else {
                throw new Error('Failed to add new customer')
            }
        })
        .then(result => {
            console.log(result)
            toast(result)
            this.setState({updateFetchOutstanding: false})
        })
        .catch(error => {
            toast(error)
            console.log(error)
            this.setState({updateFetchOutstanding: false})
        })
    }

    render() {
        return (
            <div>
                <button className='admin-panel-customer-details-back-button' onClick={this.props.closeDetails}>Back to Orders</button>
                <div className='admin-panel-orders-header-wrapper'>
                    {this.props.addCustomer ? 
                        <h1 className='admin-panel-page-title-text'>Add Customer</h1>
                    :
                        <h1 className='admin-panel-page-title-text'>User #{this.props.customerSelected.CustomerId} - {this.props.customerSelected.CustomerFirstName} {this.props.customerSelected.CustomerLastName}</h1>
                    }
                    {!this.props.addCustomer && 
                        <div className='admin-panel-customer-resend-invoice-container'>
                            {this.state.invoiceFetchOutstanding ?
                                <div className='admin-panel-customer-details-spinner-container'>
                                    <Spinner size={35} singleColor={'#1c4670'}/>
                                </div>
                            :
                                <button className='admin-panel-orders-add-customer-button' onClick={this.fetchResendInvoice}>Resend Invoice</button>
                            }
                        </div>
                    }
                </div>
                <div className='admin-panel-customer-details-container'>
                    <h2 className='admin-panel-customer-details-title' style={{marginTop: '0px'}}>Customer Info</h2>
                    <div className='admin-panel-customer-details-section'>
                        <div className='admin-panel-customer-details-field-container'>
                            <label className='admin-panel-customer-details-field-title' htmlFor="customer-id">Customer Id</label>
                            <input className='admin-panel-customer-details-field-input' id="customer-id" value={this.state.customerId} onChange={(event) => this.setState({customerId: event.target.value})}/>
                        </div>
                        <div className='admin-panel-customer-details-field-container'>
                            <label className='admin-panel-customer-details-field-title' htmlFor="first-name">First Name</label>
                            <input className='admin-panel-customer-details-field-input' id="first-name" value={this.state.firstName} onChange={(event) => this.setState({firstName: event.target.value})}/>
                        </div>
                        <div className='admin-panel-customer-details-field-container'>
                            <label className='admin-panel-customer-details-field-title' htmlFor="last-name">Last Name</label>
                            <input className='admin-panel-customer-details-field-input' id="last-name" value={this.state.lastName} onChange={(event) => this.setState({lastName: event.target.value})}/>
                        </div>
                        <div className='admin-panel-customer-details-field-container'>
                            <label className='admin-panel-customer-details-field-title' htmlFor="email">Email</label>
                            <input className='admin-panel-customer-details-field-input' id="email" value={this.state.email} onChange={(event) => this.setState({email: event.target.value})}/>
                        </div>
                        <div className='admin-panel-customer-details-field-container'>
                            <label className='admin-panel-customer-details-field-title' htmlFor="username">Username</label>
                            <input className='admin-panel-customer-details-field-input' id="username" value={this.state.username} onChange={(event) => this.setState({username: event.target.value})}/>
                        </div>
                        <div className='admin-panel-customer-details-field-container'>
                            <label className='admin-panel-customer-details-field-title' htmlFor="password">Password</label>
                            <input className='admin-panel-customer-details-field-input' id="password" value={this.state.password} onChange={(event) => this.setState({password: event.target.value})}/>
                        </div>
                        <div className='admin-panel-customer-details-field-container'>
                            <label className='admin-panel-customer-details-field-title' htmlFor="address" >Address</label>
                            <input className='admin-panel-customer-details-field-input' id="address" value={this.state.address} onChange={(event) => this.setState({address: event.target.value})}/>
                        </div>
                        <div className='admin-panel-customer-details-field-container'>
                            <label className='admin-panel-customer-details-field-title' htmlFor="address2" >Address 2</label>
                            <input className='admin-panel-customer-details-field-input' id="address2" value={this.state.address2} onChange={(event) => this.setState({address2: event.target.value})}/>
                        </div>
                        <div className='admin-panel-customer-details-field-container'>
                            <label className='admin-panel-customer-details-field-title' htmlFor="zip">Zip</label>
                            <input className='admin-panel-customer-details-field-input' id="zip" value={this.state.zip} onChange={(event) => this.setState({zip: event.target.value})}/>
                        </div>
                        <div className='admin-panel-customer-details-field-container'>
                            <label className='admin-panel-customer-details-field-title' htmlFor="state">State</label>
                            <input className='admin-panel-customer-details-field-input' id="state" value={this.state.state} onChange={(event) => this.setState({state: event.target.value})}/>
                        </div>
                        <div className='admin-panel-customer-details-field-container'>
                            <label className='admin-panel-customer-details-field-title' htmlFor="city">City</label>
                            <input className='admin-panel-customer-details-field-input' id="city" value={this.state.city} onChange={(event) => this.setState({city: event.target.value})}/>
                        </div>
                        <div className='admin-panel-customer-details-field-container'>
                            <label className='admin-panel-customer-details-field-title' htmlFor="phone-number">Phone Number</label>
                            <input className='admin-panel-customer-details-field-input' id="phone-number" value={this.state.phoneNumber} onChange={(event) => this.setState({phoneNumber: event.target.value})}/>
                        </div>
                        <div className='admin-panel-customer-details-field-container'>
                            <label className='admin-panel-customer-details-field-title' htmlFor="customer-notes">Customer Notes</label>
                            <textarea className='admin-panel-customer-details-field-text-area' id="customer-notes" value={this.state.notes} onChange={(event) => this.setState({notes: event.target.value})}/>
                        </div>
                        <div className='admin-panel-customer-details-field-container'>
                            <label className='admin-panel-customer-details-field-title' htmlFor="last-logged-in">Last Logged In</label>
                            <input className='admin-panel-customer-details-field-input' id="last-logged-in" value={this.state.lastLoggedIn} onChange={(event) => this.setState({lastLoggedIn: event.target.value})}/>
                        </div>
                    </div>
                    <h2 className='admin-panel-customer-details-title'>Product Info</h2>
                    <div className='admin-panel-customer-details-section'>
                        <div className='admin-panel-customer-details-field-container'>
                            <label className='admin-panel-customer-details-field-title' htmlFor="license-expiration">License Expiration</label>
                            <input className='admin-panel-customer-details-field-input' id="license-expiration" value={this.state.licenseExpiration} onChange={(event) => this.setState({licenseExpiration: event.target.value})}/>
                        </div>
                        <div className='admin-panel-customer-details-field-container'>
                            <label className='admin-panel-customer-details-field-title' htmlFor="date-created">Date Created</label>
                            <input className='admin-panel-customer-details-field-input' id="date-created" value={this.state.dateCreated} onChange={(event) => this.setState({dateCreated: event.target.value})}/>
                        </div>
                        <div className='admin-panel-customer-details-field-container'>
                            <label className='admin-panel-customer-details-field-title' htmlFor="date-modified">Date Modified</label>
                            <input className='admin-panel-customer-details-field-input' id="date-modified" value={this.state.dateModified} onChange={(event) => this.setState({dateModified: event.target.value})}/>
                        </div>
                        <div className='admin-panel-customer-details-field-container'>
                            <label className='admin-panel-customer-details-field-title' htmlFor="default-ip">Default IP Address</label>
                            <input className='admin-panel-customer-details-field-input' id="default-ip" value={this.state.defaultIp} onChange={(event) => this.setState({defaultIp: event.target.value})}/>
                        </div>
                        <div className='admin-panel-customer-details-switch-container'>
                            <div className='admin-panel-customer-details-switch-wrapper'>
                                <label className='admin-panel-customer-details-field-title' htmlFor="ip-limit">Limited To One Ip</label>
                                <Switch
                                    id="ip-limit"
                                    onChange={() => this.setState({limitIp: !this.state.limitIp})}
                                    value={this.state.limitIp}
                                />
                            </div>
                            <div className='admin-panel-customer-details-switch-wrapper'>
                                <label className='admin-panel-customer-details-field-title' htmlFor="case-limit">Limited To Five Cases</label>
                                <Switch
                                    id="case-limit"
                                    onChange={() => this.setState({limitCases: !this.state.limitCases})}
                                    value={this.state.limitCases}
                                />
                            </div>
                        </div>
                        <div className='admin-panel-customer-details-field-container'>
                            <label className='admin-panel-customer-details-field-title' htmlFor="demo">Demo</label>
                            <Switch
                                id="demo"
                                onChange={() => this.setState({demo: !this.state.demo})}
                                value={this.state.demo}
                            />
                        </div>
                    </div>
                    <h2 className='admin-panel-customer-details-title'>Purchase Info</h2>
                    <div className='admin-panel-customer-details-section'>
                        <div className='admin-panel-customer-details-field-container'>
                            <label className='admin-panel-customer-details-field-title' htmlFor="purchase-ip">Purchase IP</label>
                            <input className='admin-panel-customer-details-field-input' id="purchase-ip" value={this.state.purchaseIp} onChange={(event) => this.setState({purchaseIp: event.target.value})}/>
                        </div>
                        <div className='admin-panel-customer-details-field-container'>
                            <label className='admin-panel-customer-details-field-title' htmlFor="purchase-price">Purchase Price</label>
                            <input className='admin-panel-customer-details-field-input' id="purchase-price" value={this.state.purchasePrice} onChange={(event) => this.setState({purchasePrice: event.target.value})}/>
                        </div>
                        <div className='admin-panel-customer-details-field-container'>
                            <label className='admin-panel-customer-details-field-title' htmlFor="taxes">Taxes</label>
                            <input className='admin-panel-customer-details-field-input' id="taxes" value={this.state.taxes} onChange={(event) => this.setState({taxes: event.target.value})}/>
                        </div>
                        <div className='admin-panel-customer-details-field-container'>
                            <label className='admin-panel-customer-details-field-title' htmlFor="product-id">Product Id</label>
                            <input className='admin-panel-customer-details-field-input' id="product-id" value={this.state.productId} onChange={(event) => this.setState({productId: event.target.value})}/>
                        </div>
                        <div className='admin-panel-customer-details-field-container'>
                            <label className='admin-panel-customer-details-field-title' htmlFor="order-number">Order Number</label>
                            <input className='admin-panel-customer-details-field-input' id="order-number" value={this.state.orderNumber} onChange={(event) => this.setState({orderNumber: event.target.value})}/>
                        </div>
                        <div className='admin-panel-customer-details-field-container'>
                            <label className='admin-panel-customer-details-field-title' htmlFor="payment-method">Payment Method</label>
                            <input className='admin-panel-customer-details-field-input' id="payment-method" value={this.state.paymentMethod} onChange={(event) => this.setState({paymentMethod: event.target.value})}/>
                        </div>
                        <div className='admin-panel-customer-details-field-container'>
                            <label className='admin-panel-customer-details-field-title' htmlFor="where-did-you-hear">Where Did You Hear</label>
                            <input className='admin-panel-customer-details-field-input' id="where-did-you-hear" value={this.state.whereDidYouHear} onChange={(event) => this.setState({whereDidYouHear: event.target.value})}/>
                        </div>
                        <div className='admin-panel-customer-details-field-container'>
                            <label className='admin-panel-customer-details-field-title' htmlFor="demo-conversion">Conversion From Demo</label>
                            <Switch
                                id="demo-conversion"
                                onChange={() => this.setState({conversionFromDemo: !this.state.conversionFromDemo})}
                                value={this.state.conversionFromDemo}
                            />
                        </div>
                    </div>
                    <h2 className='admin-panel-customer-details-title'>Account Settings</h2>
                    <div className='admin-panel-customer-details-section'>
                        <div className='admin-panel-customer-details-field-container'>
                            <label className='admin-panel-customer-details-field-title' htmlFor="time-zone">Time Zone</label>
                            <input className='admin-panel-customer-details-field-input' id="time-zone" value={this.state.timeZone} onChange={(event) => this.setState({timeZone: event.target.value})}/>
                        </div>
                        <div className='admin-panel-customer-details-field-container'>
                            <label className='admin-panel-customer-details-field-title' htmlFor="loading-pop-up">Loading Pop Up</label>
                            <Switch
                                id="loading-pop-up"
                                onChange={() => this.setState({loadingPopup: !this.state.loadingPopup})}
                                value={this.state.loadingPopup}
                            />
                        </div>
                    </div>
                    <div className='admin-panel-customer-details-save-container'>
                        {this.state.updateFetchOutstanding ?
                            <div className='admin-panel-customer-details-spinner-container'>
                                <Spinner size={35} singleColor={'#1c4670'}/>
                            </div>
                        :
                            <button className='admin-panel-customer-details-save-button' onClick={this.saveHandler}>{this.props.addCustomer ? "Add Customer" : "Save Changes"}</button>
                        }
                    </div>
                </div>
                <ToastContainer />
            </div>
        )
    }
}
