import React from 'react'
import './Switch.css'

export default function Switch(props) {
    return (
        <div className='bool-switch-container' onClick={props.onChange} id={props.id}>
            <div className={`bool-switch-box bool-switch-false ${!props.value ? "bool-switch-box-filled" : ""}`}>
                
            </div>
            <div className={`bool-switch-box bool-switch-true ${props.value ? "bool-switch-box-filled" : ""}`}>

            </div>
        </div>
    )
}
